body {

}

h3 {
  font-weight: 300;
  color: #000000;
  margin-top: 0;
  margin-bottom: 15px;
}

hr {
  display: block;
  border: 0;
  width: 100%;
  height: 1px;
  background: #cccccc;
  margin: 30px 0;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

header {
  background: url('/img/photo.jpg') no-repeat center bottom;
  min-height: 480px;

  .container {
    text-align: center;
    position: relative;
  }

  .header-ico {
    display: block;
    position: absolute;
    background-position: right center;
    background-repeat: no-repeat;
    padding-top: 5px;
    padding-bottom: 5px;
    &:hover {
      text-decoration: none;
    }
    &.ico-email {
      background-image: url('/img/ico-envelop.png');
      padding-right: 37px;
    }
    &.ico-phone {
      background-image: url('/img/ico-phone.png');
      padding-right: 31px;
    }
  }

  .ico-menu {
    @media (max-width: $screen-xs-max) {
      display: block;
    }
    @media (min-width: $screen-sm-min) {
      display: none;
    }
    position: absolute;
    left: 30px;
    top: 30px;
    height: 25px;
    width: 30px;
    cursor: pointer;
    background-image: url('/img/ico-menu.png');
    background-repeat: no-repeat;
    background-position: center top;
    &:hover, &.active {
      background-position: center bottom;
    }
  }

  @media (max-width: $screen-xs-max) {
    .header-ico {
      display: none;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    .header-ico {
      right: 0;
      &.ico-email {
        top: 5px;
      }
      &.ico-phone {
        top: 40px;
      }
    }
  }
  @media (min-width: $screen-lg-min) {
    .header-ico {
      top: 20px;
      &.ico-email {
        right: 0;
      }
      &.ico-phone {
        right: 200px;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .logo {
      img {
        width: 220px;
      }
    }
  }
}

.padding-30 {
  padding: 30px;
}

.padding-b-30 {
  padding-bottom: 30px;
}

.padding-tb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

nav.main {
  @media (max-width: $screen-xs-max) {
    display: none;
    position: absolute;
    top: 0;
    left: 90px;
    z-index: 1000;
    background: $brand-primary;
    &.active {
      display: block;
    }
    ul {
      margin: 0;
      padding: 15px 0;
      li {
        list-style: none;
        &:hover {
          background: #ffffff;
        }

        a {
          font-size: 22px;
          display: inline-block;
          padding: 15px 50px;
          color: #ffffff;
          &:hover {
            color: $brand-primary;
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    height: 60px;
    text-align: center;
    font-size: 0;
    border-bottom: 1px solid #dddddd;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      li {
        display: inline-block;
        padding: 0;
        margin: 0;
        a {
          display: block;
          height: 59px;
          font-size: 16px;
          padding: 16px 30px 0 30px;
          border-bottom: 5px solid #ffffff;
          text-decoration: none;
          color: #333333;
          &.active, &:hover {
            color: $brand-primary;
            border-bottom: 5px solid $brand-primary;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.constructions {
  text-align: center;
  padding: 30px 0 0 0;
  h3 {
    text-align: center;
    color: $brand-primary;
    margin: 0;
    padding: 0 30px 5px 30px;
  }
  p {
    color: #000000;
    margin: 0;
    padding: 0 30px;
    font-weight: bold;
  }

  .constructions-items {
    margin-top: 30px;
    padding-top: 25px;
    min-height: 673px;
    @media (max-width: $screen-sm-max) {
      background: #f1f1f1;
    }
    @media (min-width: $screen-md-min) {
      background: url('/img/constructions.jpg') no-repeat center top;
    }

    .row {
      >div {
        padding-top: 5px;
        h4 {
          font-size: 18px;
          text-align: center;
          color:#333333;
          padding-top: 2px;
          min-height: 50px;
        }
      }
    }
  }
}

.info {
  @media (min-width: $screen-md-min) {
    background: url('/img/content-bg.jpg') repeat-y center;
  }

  .info-bg {
    @media (min-width: $screen-md-min) {
      background: url('/img/content.jpg') no-repeat bottom center;
    }

    .row {
      >div {
        @media (max-width: $screen-sm-max) {
          background: #efefef;
        }
        padding: 30px;
        ul {
          padding-left: 20px;
        }
        h3 {
          margin-top: 0;
        }
        &.grey {
          @media (max-width: $screen-sm-max) {
            background: #333333;
          }
          color: #fff;
          h3 {
            color: #f4e4ca;
          }
        }
      }
    }
  }

}

footer {
  padding-top: 30px;
  padding-bottom: 30px;
  .row {
    > div {
      min-height: 200px;
    }
  }
  h3 {
    text-transform: uppercase;
    margin-top: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: red;
      text-decoration: none;
    }
  }
}

#contact-map {
  @media (max-width: $screen-xs-max) {
    height: 500px;
  }
  @media (min-width: $screen-sm-min) {
    height: 700px;
  }
}